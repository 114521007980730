/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    section: "section",
    div: "div",
    p: "p",
    strong: "strong",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, Button} = _components;
  if (!Button) _missingMdxReference("Button", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents), React.createElement(_components.section, {
    className: "uk-section uk-position-relative  uk-background-dark uk-light uk-position-z-index  ",
    id: ""
  }, React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Canopy PACT is focused on addressing the ", React.createElement(_components.strong, null, "twin crises"), " of climate change and biodiversity loss, much of which is attributed to avoidable deforestation.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "With this in mind, we are developing pathways to impact for world-leading research in forest lifecycles, biodiversity, remote sensing technology, AI and planetary-scale computing.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "We are engaging across the buy and sell-sides of the market, in addition to national governments, jurisdictional agencies, bodies such as ICVCM, VCMI and selected standards, accreditation and verification organisations.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "A ", React.createElement(_components.a, {
    href: "https://www.cambridge.org/engage/coe/article-details/679385946dde43c9082f7009"
  }, "University of Cambridge report"), " from the ", React.createElement(_components.a, {
    href: "https://4c.cst.cam.ac.uk/"
  }, "Cambridge Centre for Carbon Credits (4C)"), " found that:")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Carbon credits from nature-based solutions were the best value in terms of their climate and biodiversity impact"), "\n", React.createElement(_components.li, null, "All current forest carbon credits need to be recalibrated to represent their carbon contribution accurately"), "\n"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Over the past three years, 4C has produced and published methods to create high-quality scientific evaluations of forest carbon projects.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "To realise the transformative value of 4C's research to nature credit markets, Canopy PACT was established as a Registered UK Charity in early 2025.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Built on values of objectivity and impartiality, the mission statement of Canopy PACT is to democratise access to the best science and evaluation methods for protecting forest carbon in the biodiverse tropics.")), React.createElement(Button, {
    url: "/science/",
    style: "primary"
  }, "Find out more about our approach"))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
